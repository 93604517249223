<template>
  <el-footer height="auto">
    <el-divider></el-divider>
    <el-link href="https://zhangguapi.com/" target="_blank">建议反馈</el-link>
    <el-link href="https://zhangguapi.com/" target="_blank">举报投诉</el-link>
    <el-link href="https://zhangguapi.com/" target="_blank">隐私政策</el-link>
    <el-link href="https://zhangguapi.com/" target="_blank">关于网站</el-link>
    <div>
    <el-link style="margin: 10px">&copy;2022 zhangguapi</el-link>
    </div>
  </el-footer>
</template>
<script>
export default {

}
</script>
<style lang="less" scoped>
.el-footer {
  width: 100%;
  text-align: center;
}

.el-link {
  margin-left: 10px;
  margin-right: 10px;


}
</style>
